import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetTokenParams } from '@rocklabs-io/jelly-js';
import { Principal } from '@dfinity/principal';
import { jellyJsInstanceHandler } from '../../../../integrations/jelly-js';
import { createActor } from '../../../../integrations/actor';
import { nftsActions } from '../nfts-slice';
import { marketplaceSlice } from '../../marketplace/marketplace-slice';
import { getJellyCollection } from '../../../../utils/jelly';
import { AppLog } from '../../../../utils/log';
import { getPrincipal } from '../../../../integrations/plug';

export const getMyNFTs = createAsyncThunk<any | undefined, any>(
  'marketplace/getMyNFTs',
  async (
    { collectionId, shouldFetchUserTokenIdsAlone },
    thunkAPI,
  ) => {
    const { dispatch } = thunkAPI;

    dispatch(nftsActions.setNFTsTotalCount(0));
    // Checks if an actor instance exists already
    // otherwise creates a new instance
    const jellyInstance = await jellyJsInstanceHandler({
      thunkAPI,
      slice: marketplaceSlice,
    });

    if (!shouldFetchUserTokenIdsAlone) {
      // set loading NFTS state to true
      dispatch(nftsActions.setIsNFTSLoading(true));
    }

    try {
      const collection = await getJellyCollection({
        jellyInstance,
        collectionId,
      });

      if (!collection)
        throw Error(`Oops! collection ${collectionId} not found!`);

      const jellyCollection = await jellyInstance.getJellyCollection(
        collection,
      );

      const actor = await createActor({
        serviceName: 'dip721',
        collectionId,
      });

      const userNFTIds = await (async () => {
        const res = await actor.dip721_owner_token_identifiers(
          // Principal.fromText("")
          await getPrincipal(),
        );

        try {
          const responseNFTIds = res.Ok;

          if (!responseNFTIds) return [];

          const parsedResponse = responseNFTIds.map(
            (tokenId: BigInt) => tokenId.toString(),
          );

          return parsedResponse;
        } catch (err) {

          console.error(err);
        }
      })();

      console.log(userNFTIds, 'userNFTIds');

      if (!shouldFetchUserTokenIdsAlone) {

        if (
          userNFTIds &&
          userNFTIds.length &&
          userNFTIds.length > 0
        ) {
          const limit = 20;
          const totalNFTsCount = userNFTIds.length;
          const totalPages = Math.ceil(totalNFTsCount / limit);
          let remainingCount = totalNFTsCount;
          let startIndex = 0;
          let endIndex = 0;
          const slicedNFTIds: string[][] = [];
          let fetchedNFTs: any = [];

          for (
            let currentPageNumber = 1;
            currentPageNumber < totalPages + 1;
            currentPageNumber++
          ) {
            startIndex = endIndex;
            if (remainingCount > limit) {
              endIndex = currentPageNumber * limit;
            } else {
              endIndex =
                (currentPageNumber - 1) * limit + remainingCount;
            }

            console.log(
              currentPageNumber,
              startIndex,
              endIndex,
              'index',
            );

            const nftIdsToFetch = userNFTIds.slice(
              startIndex,
              endIndex,
            );

            slicedNFTIds.push(nftIdsToFetch)

            remainingCount = totalNFTsCount - endIndex;
          }

          Promise.all(slicedNFTIds.map((ids) => jellyCollection.getNFTs({
            ids // ['8633']
          }))).then(res => {
            res.forEach((result) => {
              if (result.ok) {
                const { data = [] } = result;
                console.log(data.map(it => it.id))
                fetchedNFTs = [...fetchedNFTs, ...data];

                // if (fetchedNFTs.length !== slicedNFTIds[index].length) {
                //   throw Error("NFT data lost.");
                // }

              } else {
                console.error("Fail to fetch NFT data.");
              }
            })

            const extractedNFTSList = fetchedNFTs.map((nft: any) => {
              const metadata = {
                id: nft.id,
                name: nft.collectionName,
                price: nft?.price,
                lastOffer: nft?.lastOffer,
                lastSale: nft?.lastSale,
                preview: nft?.thumbnail,
                location: nft?.location,
                traits: nft?.traits,
                status: nft?.lastActionTaken,
                owner: nft?.owner,
                operator: nft?.operator,
                rendered: true,
                lastActionTaken: nft?.lastActionTaken,
                listing: nft?.listing,
                lastListingTime: nft?.lastListingTime,
                offers: nft?.offers,
                lastOfferTime: nft?.lastOfferTime,
                lastSaleTime: nft?.lastSaleTime,
              };
              return metadata;
            });

            const actionPayload = {
              loadedNFTList: extractedNFTSList,
              totalPages: 1,
              total: fetchedNFTs.length,
              nextPage: 1,
              lastIndex: undefined,
            };

            // update store with loaded NFTS details
            dispatch(nftsActions.setLoadedNFTS(actionPayload));
            dispatch(nftsActions.setNFTsTotalCount(userNFTIds.length));
          }).catch((error) => { })

          console.log(fetchedNFTs, 'fetchedNFTs');
        } else {
          const getUserNFTs = await jellyCollection.getNFTs({
            ids: userNFTIds,
          });

          if (!getUserNFTs.ok) {
            throw Error(`Oops! Failed to get my NFTs`);
          }
          const { data = [] } = getUserNFTs;

          // TODO: map nft list
          const extractedNFTSList = data.map((nft: any) => {
            const metadata = {
              id: nft.id,
              name: nft.collectionName,
              price: nft?.price,
              lastOffer: nft?.lastOffer,
              lastSale: nft?.lastSale,
              preview: nft?.thumbnail,
              location: nft?.location,
              traits: nft?.traits,
              status: nft?.lastActionTaken,
              owner: nft?.owner,
              operator: nft?.operator,
              rendered: true,
              lastActionTaken: nft?.lastActionTaken,
              listing: nft?.listing,
              lastListingTime: nft?.lastListingTime,
              offers: nft?.offers,
              lastOfferTime: nft?.lastOfferTime,
              lastSaleTime: nft?.lastSaleTime,
            };
            return metadata;
          });

          const actionPayload = {
            loadedNFTList: extractedNFTSList,
            totalPages: 1,
            total: data.length,
            nextPage: 1,
            lastIndex: undefined,
          };

          // update store with loaded NFTS details
          dispatch(nftsActions.setLoadedNFTS(actionPayload));
          dispatch(nftsActions.setNFTsTotalCount(userNFTIds.length));
        }
      }

      const myNFTIds = userNFTIds || [];

      // update store with loaded my NFT Ids
      dispatch(nftsActions.setMyNFTIds(myNFTIds));

      if (!myNFTIds) {
        AppLog.warn('Oops! Failed to get all NFTs via Jelly-js!');

        return [];
      }

      return myNFTIds;
    } catch (err) {
      AppLog.error(err);

      dispatch(nftsActions.setNFTsTotalCount(0));
      dispatch(nftsActions.setIsNFTSLoading(false));
    }
  },
);
